/*
 * @Description: 商城配置数据
 */
import defaultLogo from '@/assets/image/notice.png'

export const mallTypeList = [
    {
        type: 'mall',
        logo: defaultLogo,
        title: '微商城',
        subTitle: ' E-commence'
    },
    {
        type: 'model',
        logo: defaultLogo,
        title: '商城模板',
        subTitle: ' E-commenceModel'
    },
]

export const mallIndustryList = [
    { label: '日用百货', value: 'department' },
    { label: '服饰饰品', value: 'clothes' },
    { label: '休闲零食', value: 'snacks' },
    { label: '数码家电', value: 'digital' },
    { label: '教育培训', value: 'education' },
    { label: '酒店旅游', value: 'hotel' },
    { label: '其他', value: 'other' },
]