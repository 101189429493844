<!--模板商城-->
<template>
  <div>
    <div class="wrap">
      <!-- 行业分类 -->
      <ul class="wrap-head">
        <li v-for="(item, index) in industryList" :key="index" class="wrap-head-item"
          :class="[item.IndustryID == industry ? 'wrap-head-active' : '']" @click="industry = item.IndustryID">
          {{ item.Title }}
        </li>
        <li>
          <el-input placeholder="关键字" size="small" v-model="Keywords" clearable @clear="handleKeyup"
            @keyup.enter.native="handleKeyup"></el-input>
        </li>
      </ul>
      <!-- 模板列表 -->
      <div v-loading="loading">
        <ul v-if="list.length" class="wrap-list">
          <li v-for="model in list" :key="model.MartID" class="model">
            <template>
              <img class="model-img" :src="model.Cover" />
              <div class="model-desc">
                <h3 class="mt5 f14">{{ model.Name }}</h3>
                <el-tag effect="plain" size="mini" class="mt5">{{ model.industry_name || '未知分类' }}</el-tag>
                <p class="hot-tag" v-if="model.UseCount">
                  <img src="../../../src/assets/image/hot.png" alt="">
                  <span>{{ model.UseCount }}</span>
                </p>
              </div>
            </template>

            <template>
              <div class="model-qr">
                <img class="w90 mb5" :src="getQr(model.id)" />
                <span>扫码预览</span>
              </div>

              <span class="model-btn" @click="useModel(model)">使用模板</span>
            </template>
          </li>

          <li style="text-align: center;margin-top: 20px;">
            <el-pagination background :page-size="paginationForm.PageSize" layout="total, prev, pager, next"
              :total="totalCount" @current-change="handleCurrentChange" />
          </li>
        </ul>

        <!-- 空列表 -->
        <el-empty v-else class="mt80">
          <template slot="description">
            <span class="f13 f-grey">{{ `没有模版数据哦` }}</span>
          </template>
        </el-empty>
      </div>

    </div>


  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { getModelList, getProjectById, getPageTemplateWidgets } from "@/api/project";

import { getShopIndustries } from '@/api/goods'
import { mallIndustryList, mallTypeList } from "@/config/mall";
import global from "@/config/global";

export default {
  created() {
    // this.getIndustryName();
    this.getIndustryList()
  },

  data() {
    return {
      loading: false,
      industry: "",
      Keywords: "",
      list: [],
      // industryList: [{ label: "全部", value: "" }, ...mallIndustryList],
      industryList: [],
      totalCount: 0,
      paginationForm: {
        PageIndex: 1,
        // total: 0,
        PageSize: 10
      }
    };
  },

  computed: {
    ...mapGetters(["project", "userInfo"]),
  },

  watch: {
    industry: {
      immediate: true,
      handler() {
        this.getModelList();
      },
    },
  },

  methods: {
    ...mapMutations(["setProject", "setCurPage"]),
    handleKeyup() {
      this.getModelList()
    },
    getIndustryList() {
      getShopIndustries().then(res => {
        this.industryList = [{ Title: "全部", IndustryID: "" }, ...res]
      })
    },
    // 模板商城暂时只展示自己的
    async getModelList() {
      this.loading = true;
      let { List, TotalCount } = await getModelList({
        IndustryID: this.industry,
        Keywords: this.Keywords,
        ...this.paginationForm
      });
      this.list = List;
      this.totalCount = TotalCount
      this.loading = false;
    },

    getIndustryName(id) {
      const _tag = this.industryList.find(f => f.industry == id)

      return _tag?.Title ?? '未知分类'
    },

    async useModel(model) {
      // let { data } = await getProjectById({ id: model.id })
      let { _id, name, userId } = this.project;
      console.log(this.project)
      const _data = {
        shopID: model.MartID,
      }
      getPageTemplateWidgets(_data).then(res => {
        if (res) {
          const _res = this.$cloneDeep(res)
          _res.componentList = _res.componentList?.map(m => {
            if (m.component == 'McRecommendedGoods') {
              m.type = {
                productType: {
                  id: m.productType,
                  iRecommendIDd: m.RecommendID,
                  featureIDs: m.featureIDs,
                }
              }
            }
            if (m.component == 'McActivityGroup') {
              m.list = {
                type: {
                  id: m.type,
                  iRecommendIDd: m.RecommendID,
                  promotionIDs: m.promotionIDs,
                }
              }
            }
            if (m.component == 'McSwiper') {
              m.styles.componentHeight = m.height
              m.styles.MarginTop = m.MarginTop
            } else {
              if (m.space) {
                m.space.MarginTop = m.MarginTop
              } else {
                m.space = {
                  MarginTop: m.MarginTop
                }
              }
            }
            // 转化热区比例到750
            if (m.component == 'McImgHot') {
              const _list = [...m.imageValue.HotspotList]
              m.imageValue.HotspotList = _list.map(_m => {
                _m.top = _m.top * 750 / m.imageValue.naturalWidth
                _m.left = _m.left * 750 / m.imageValue.naturalWidth
                _m.width = _m.width * 750 / m.imageValue.naturalWidth
                _m.height = _m.height * 750 / m.imageValue.naturalWidth
                return { ..._m }
              })
            }
            return { ...m }
          })
          this.setCurPage(_res)
          this.$message({
            type: "success",
            message: "已使用选中模板!",
          });
          this.$router.push({
            name: "page-build"
          });
        }
      })

      // let map = new Map();
      // mallTypeList.map((item) => map.set(item.type, item.logo));

      // // 模板上配置相关商城数据
      // let mall = {
      //   ...this.$cloneDeep(model),
      //   ...{ _id, name, userId, type: "mall", logo: map.get("mall") },
      // };

      // this.setProject(mall);
      // this.$router.push({ name: "page-build" });

      // this.$message({
      //   type: "success",
      //   message: "已使用选中模板!",
      // });
    },

    getQr(id) {
      let url = `${global.viewUrl}pages/index/tabbar/home?id=${id}`;

      let options = {
        padding: 0, // 二维码四边空白（默认为10px）
        width: 180, // 二维码图片宽度（默认为256px）
        height: 180, // 二维码图片高度（默认为256px）
        reverse: false, // 反色二维码，二维码颜色为上层容器的背景颜色
        background: "#ffffff", // 二维码背景颜色（默认白色）
        foreground: "#000000", // 二维码颜色（默认黑色）
      };
      console.log("预览地址1:" + url);
      return jrQrcode.getQrBase64(url, options);
    },

    handleCurrentChange(p) {
      this.$set(this.paginationForm, "PageIndex", p);
      this.getModelList();
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.el-button) {
  background: $color-theme;
}

.el-tag {
  color: $color-theme;
  border-color: $color-theme;
}

.hot-tag {
  display: flex;
  align-items: flex-end;

  img {
    width: 25px;
    height: 25px;
  }
}

.wrap {
  margin-top: 20px;

  .wrap-head {
    display: flex;
    margin-bottom: 10px;
    align-items: center;

    .wrap-head-item {
      padding: 8px 12px;
      // margin: 0 8px 8px 0;
      margin-right: 8px;
      background: #f2f2f6;
      border-radius: 4px;
      font-size: 12px;
      text-align: center;
      cursor: pointer;
    }

    .wrap-head-active {
      color: $color-theme;
      background: rgba(37, 137, 255, 0.1);
    }
  }

  .wrap-list {
    height: 75vh;
    overflow: auto;

    .model {
      position: relative;
      display: inline-block;
      width: 220px;
      max-height: 380px;
      margin: 0 20px 20px 0;
      border: 1px solid #e9e9e9;
      border-radius: 4px;
      overflow: hidden;
      cursor: pointer;

      .model-img {
        width: 100%;
        min-height: 400px;
      }

      .model-desc {
        position: absolute;
        left: 0;
        bottom: -30px;
        width: 100%;
        height: 115px;
        padding: 10px;
        background: hsla(0, 0%, 100%, 0.95);
        font-size: 12px;
        color: #333;
        transition: all 0.3s;
        z-index: 10;
      }

      .model-qr {
        display: none;
        position: absolute;
        top: 20%;
        left: 50%;
        width: 110px;
        padding: 10px;
        border-radius: 6px;
        transform: translateX(-50%);
        background: #fff;
        font-size: 12px;
        text-align: center;
        color: #595961;
        z-index: 10;
      }

      .model-btn {
        display: none;
        position: absolute;
        top: 56%;
        left: 50%;
        width: 110px;
        height: 28px;
        line-height: 28px;
        border-radius: 4px;
        transform: translateX(-50%);
        background: $color-theme;
        font-size: 12px;
        color: #fff;
        text-align: center;
        z-index: 10;
      }

      &:hover {
        &::after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: #68656530;
          z-index: 1;
          transition: all 0.3s;
        }

        .model-desc {
          bottom: 0px;
        }

        .model-btn,
        .model-qr {
          display: inline-block;
        }
      }
    }
  }
}
</style>